@import 'styles/main';

nav.bread-crumb {
  overflow: hidden;
  @include media-breakpoint-up(m) {
    ol {
      list-style: none;
      li {
        white-space: nowrap;
        &:last-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:not(:last-child):after {
          content: ' > ';
          font-size: 12px;
        }
      }
    }
  }
}
