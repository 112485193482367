@import 'styles/main';
@import 'styles/text';
@import 'styles/responsive-text';

div.content-body {
  position: relative;
  @extend .p;

  &.partial:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -$spacing-l; // Be sure to set gradient on sun from blockquote
    display: block;
    width: calc(100% + #{$spacing-l});
    height: 200px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  %header-margin {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }

  h1 {
    @extend .h2;
    @extend %header-margin;
  }
  h2 {
    @extend .h3;
    @extend %header-margin;
  }
  h3 {
    @extend .h4;
    @extend %header-margin;
  }
  h4 {
    @extend .h5;
    @extend %header-margin;
  }
  h5 {
    @extend .h6;
    @extend %header-margin;
  }
  h6 {
    @extend .h6;
    @extend %header-margin;
  }

  p:not(:global(.menu-link)) {
    @extend .p;
    margin-bottom: $spacing-s;
  }

  &.article {
    p:not(:global(.menu-link)) {
      @extend .new-p;
      margin-bottom: $spacing-s;
    }
  }

  ul,
  ol {
    padding-left: $spacing-m;
    margin-bottom: $spacing-s;

    li {
      @extend .p;
    }
  }

  ul {
    list-style-type: disc;
  }

  iframe {
    max-width: 100%;
  }
  video,
  audio {
    width: 100%;
    max-width: 100%;
  }
  img {
    margin: $spacing-l 0 0 0;
  }
  .ez-embed-type-image img {
    margin: $spacing-s 0 0 0;
  }
  figure {
    figcaption {
      @extend .tiny;
      color: $color-neutral-1;
      margin-bottom: $spacing-l;
    }
  }

  aside:global(.read-also) {
    $read-also-width: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $spacing-m;
    margin: $spacing-basis 0;
    border-top: none;
    background-color: $grey-cloud-light;
    @extend .h5;

    @include media-breakpoint-up(m) {
      padding: $spacing-l;
      margin: $spacing-l 0;
    }

    > *:not(:last-child) {
      margin-bottom: $spacing-s;
    }

    > a {
      position: relative;
      display: block;
      width: 100%;

      @include media-breakpoint-up(m) {
        padding-right: #{$spacing-basis + $read-also-width};

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          background: url(/icons/arrow.svg) no-repeat center right;
          background-size: $read-also-width;
          margin-left: $spacing-basis;
          height: 100%;
          width: $read-also-width;
        }
      }
    }
  }
  aside:global(.factbox) {
    margin: $spacing-basis 0;
    padding: $spacing-basis $spacing-m;
    background-color: $color-neutral-3;

    @include media-breakpoint-up(m) {
      margin: $spacing-l 0;
      padding: 32px $spacing-l $spacing-l $spacing-l;
    }

    > h2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 $spacing-xxs 0;
      font-size: 12px;
      font-family: $font-family-1;
      line-height: 12px;

      &:before {
        content: '';
        display: block;
        width: 45px;
        height: 46px;
        margin-right: 2px;
        background-image: url('/icons/see.svg'), url('/icons/yellow-sun.svg');
        background-repeat: no-repeat;
        background-size: 24px 24px, 45px 46px;
        background-position: 11px center, top left;
      }

      @include media-breakpoint-up(m) {
        margin-left: -$spacing-m;
        margin-bottom: -28px;

        &:before {
          width: 75px;
          height: 80px;
          margin-right: -15px;
          background-size: 24px 24px, 75px 80px;
          background-position: 24px center, top left;
        }
      }

      > p:last-child {
        margin-bottom: 0;
      }
    }
  }
  div:global(.citation) {
    @mixin negativeMargin($spacing, $maxWidth) {
      margin-left: -#{$spacing};
      margin-right: -#{$spacing};
      span {
        padding-left: $spacing;
        padding-right: $spacing;
        max-width: $maxWidth + 2 * $spacing;
      }
    }
    @extend %header-margin;
    @include negativeMargin($spacing-m, 438px);
    display: flex;
    justify-content: center;

    background-image: url('/img/yellow-sun.svg');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: -#{$spacing-m} 0;

    span {
      @extend %quote;
      display: block;
      padding-top: $spacing-m;
      min-height: 180px;
    }
    @include media-breakpoint-up(m) {
      @include negativeMargin($spacing-l, 438px);
      background-position: 0 0;
      span {
        padding-top: $spacing-l;
      }
    }
  }
  &-become-pro {
    background: $grey-cloud-ultralight;
  }
  .align {
    &-right {
      float: right;
    }
    &-left {
      float: left;
    }
    &-center img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  td {
    padding: $spacing-s;
  }
}


